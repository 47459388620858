import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import {
  Container, CssBaseline, makeStyles, Divider,
  Box, Typography, Grid, Button, Link, TextField, Snackbar, CircularProgress, FormLabel,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper
} from "@material-ui/core"
import SEO from "../components/seo"
import PhoneInput from 'react-phone-input-2'
import {
  API_ADDRESS,
  createEnum,
  EMAIL_REGEX,
  NOTIFICATION_LAYER,
  PHONE_REGEX,
  REGISTRATION_KEYS,
} from "../lib/constants"
import axios from "axios"
import { setAuthToken } from "../lib/auth"
import { navigate } from "@reach/router"
import {API_ERROR_CODES} from "../lib/constants";
import hasParams from "../components/has_params"
import SharedStyles from '../components/shared_styles'
import {apiGet} from "../lib/api"
import { ElementOrLoader } from "../components/util"

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  phoneInput: {
    // Need important to override the plugin's default style.
    height: '48px !important',
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
      height: '56px !important',
    }
  },
  button: {
    marginBottom: theme.spacing(2),
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '56px'
    }
  },
  logo: {
    width: '270px',
    height: '50px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
}));

const STATE = createEnum(['PHONE', 'CODE'], 0);

const Login = ({params}) => {
  const classes = useStyles();
  const styles = SharedStyles();
  const [state, setState] = useState(STATE.PHONE);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [infoLoad, setInfoLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  useEffect(() => {
    setInfoLoad(true);
    apiGet('private-network/get-info-for-login')
      .then((response) => {
        setInfoLoad(false);
        setNetworks(response.networks);
      })
      .finally(()=>setInfoLoad(false));
  }, [])

  const handlePhoneChange = (inputPhone) => {
    setPhone(inputPhone);
  }
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  const handleCodeChange = (event) => {
    setCode(event.target.value)
  }

  const toggleState = () => {
    if (state == STATE.PHONE) {
      setState(STATE.CODE);
    } else {
      setState(STATE.PHONE)
    }
  }

  const submit = () => {
    if (phone.length < 7 && email.length < 7) {
      return alert("Enter your phone number or email address. Then click Get Login Code");
    }
    let cleanPhone=null;
    if (phone.length >= 7) {
      cleanPhone = phone.replace(/[^0-9|+]/g, '');
      if (!PHONE_REGEX.test(cleanPhone)) {
        return alert("Please enter a valid phone number.");
      }
    }
    if (email.length >= 7 && !EMAIL_REGEX.test(email)) {
      return alert("Please enter a valid email address.");
    }

    const data = {}
    if (cleanPhone) {
      data['phone'] = cleanPhone;
    } else {
      data['email'] = email;
    }
    if (state === STATE.CODE) {
      if (!code) {
        alert("Please enter your login code.")
        return;
      }
      data['code'] = code;
    }
    setLoading(true);
    axios.post(`${API_ADDRESS}/user/login`, data)
      .then((response) => {
        let error_message = '';
        if (state == STATE.PHONE) {
          if (response.data.error == API_ERROR_CODES.INVALID_REQUEST) {
            error_message = 'We\'ve already sent a login code to your phone. You have to wait 15 minutes before you can request another one.';
          } else {
            error_message = 'You should receive a message with your login code.';
          }
          setSnackBarText(error_message);
          setSnackBarOpen(true);
          setState(STATE.CODE);
          return;
        } else if (state == STATE.CODE) {
          if (response.data.error) {
              error_message = 'The code you\'re using has expired. Please request a new one.'
              setSnackBarText(error_message);
              setSnackBarOpen(true);
              return;
          }
          if (response.data.token) {
            setAuthToken(response.data.token);
            if (params && params.url) {
              navigate(params.url);
            } else {
              navigate('/app');
            }
          }
        }
      })
      .finally(()=> setLoading(false));
  }

  const getCodeInput = () => {
    if (state === STATE.PHONE) return null;
    return (
      <TextField
        label="Login Code"
        variant="outlined"
        fullWidth
        required
        value={code}
        onChange={handleCodeChange}
        disabled={loading}
      />
    );
  }

  const getButtonContent = () => {
    if (loading) {
      return (
        <CircularProgress size={14} />
      );
    }
    return state === STATE.PHONE? "Get login code" : "Login";
  }

  const handleChooseNetwork = (network) => {
    setSelectedNetwork(network);
  }

  const getNetworkSelect = () => {
    let list = [];
    list = networks.map((network, index) =>
      <ListItem
        style={{borderBottom:'1px solid #f0f0f0'}}
        button
        onClick={()=>handleChooseNetwork(network)}
        key={index}
      >
        <ListItemAvatar>
          <Avatar src={network.Logo} variant='rounded' imgProps={{style:{objectFit:'contain'}}} />
        </ListItemAvatar>
        <ListItemText primary={network.Name} />
      </ListItem>
    );
    return (
      <React.Fragment>
        <Typography variant="subtitle1" gutterBottom>
          Choose your network
        </Typography>
        <List>
          {list}
        </List>
      </React.Fragment>
    );
  }

  const getLoginFields = () => {
    let identifier_element;
    if (selectedNetwork.NotificationLayer == NOTIFICATION_LAYER.SMS) {
      identifier_element = (
        <React.Fragment>
          <FormLabel component="legend">Enter Phone Number</FormLabel>
          <PhoneInput
            inputProps={{
              name:"phone",
              id:"phone"
            }}
            inputClass={classes.phoneInput}
            onlyCountries={['us']}
            country={'us'}
            disableDropdown
            value={phone}
            onChange={handlePhoneChange}
            placeholder="Enter Phone number"
            disabled={loading}
          />
        </React.Fragment>
      );
    } else if (selectedNetwork.NotificationLayer == NOTIFICATION_LAYER.EMAIL) {
      identifier_element = (
        <React.Fragment>
          <TextField
            variant="outlined"
            label="Enter Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            required
            style={{height:'56px'}}
          />
        </React.Fragment>
      );
    }
    return (
      <Grid container spacing={1} className={styles.centerElements}>
        <Grid item xs={12}>
          {identifier_element}
        </Grid>
        <Grid item xs={12}>
          <Divider  style={{marginTop: '10px', marginBottom: '10px'}}/>
          {getCodeInput()}
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            className={classes.button}
            variant="outlined"
            onClick={submit}
            fullWidth
            disabled={loading}
          >
            {getButtonContent()}
          </Button>
          <Typography variant="subtitle2">
            <Link href="#" onClick={toggleState}>
              {state === STATE.PHONE? "I already have a code?" : "I don't have a code"}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Layout>
      <SEO title="Login"/>
      <Container maxWidth="xs">
        <CssBaseline />
        <Paper xs={12} className={classes.paper}>
          <Typography component="h1" variant="h5" gutterBottom>
            Log in
          </Typography>
          <ElementOrLoader loading={infoLoad}>
            {!selectedNetwork && getNetworkSelect()}
            {selectedNetwork && (
              <React.Fragment>
                <Box className={styles.centerColumnElements} style={{margin: '20px'}}>
                  <Box className={classes.logo} style={{backgroundImage:`url('${selectedNetwork.Logo}')`}} />
                  <Typography variant="subtitle1">{selectedNetwork.Name}</Typography>
                </Box>
              </React.Fragment>
            )}
            {selectedNetwork && getLoginFields()}
          </ElementOrLoader>
        </Paper>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        message={snackBarText}
        onClose={()=>{setSnackBarOpen(false)}}
      />
    </Layout>
  );
}

export default hasParams(Login)
